.btn {
  font: 700 16px/50px $roboto;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: $blue;
  background: #fff;
  border-radius: 32px;
  height: 50px;
  display: inline-block;
  padding: 0 15px;
  min-width: 269px;
  box-shadow: 0px 5px 5px 0 rgba(0, 0, 1, 0.2);
  transition: all .15s ease;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    box-shadow: none;
  }
}
