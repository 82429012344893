.header {
  padding: 15px 0;

  @include md {
    padding: 30px 0;
  }

  &-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav {
      right: 100%;
      position: absolute;
      top: 68px;
      z-index: 19;
      width: 100%;
      background: $blue;
      transition: all .25s ease-in;

      @include md {
        display: block;
        position: static;
        background: none;
        width: auto;
      }

      &__list {
        @include md {
          display: flex;
        }
      }

      &__item {
        margin-left: 40px;

        @include lg {
          margin-left: 70px;
        }
      }

      &__link {
        color: #fff;
        padding: 25px 10px;

        @include md {
          color: #000;
          padding: 0;
        }

        font: 700 14px/1 $roboto;
        letter-spacing: 1.12px;
        text-transform: uppercase;
        position: relative;
        display: inline-block;
        transition: all .05s ease-in;

        &:before {
          content: '';
          position: absolute;
          width: 20px;
          height: 1px;
          background: $blue;
          bottom: -3px;
          left: -10px;
          transition: all .15s ease-in;

        }

        &:hover {
          &:before {
            left: 0;
            width: 100%;
          }
        }
      }

      &.active {
        right: 0;
      }
    }


  }
}

.hero {
  margin-bottom: 30px;
  position: relative;

  @include lg {
    margin-bottom: 200px;
  }

  &:before {
    content: '';
    position: absolute;
    background: url('../img/section-img-bg.png') no-repeat center / 100% 100%;
    top: -30px;
    left: 0;
    width: 300px;
    height: 515px;
    z-index: -1;

    @include lg {
      width: 511px;
      height: 878px;
    }
  }

  &__inner {
    padding: 60px 0 30px;
    position: relative;
    z-index: 9;

    @include md {
      padding: 100px 0 30px;
    }

    @include lg {
      padding: 130px 0 181px;
    }

    h1 {
      margin-left: -5px;
      margin-bottom: 22px;
      max-width: 10em;

      span {
        letter-spacing: -6px;

        @include md {
          letter-spacing: -12px;
        }
      }
    }
  }

  &__img {
    background: url('../img/hero-img.jpg') no-repeat 100% / cover;
    width: 100%;
    min-height: 300px;

    @include md {
      position: absolute;
      width: 50%;
      right: 0;
      top: 0;
      height: 100%;
    }

    @include lg {
      width: 55.5vw;
    }
  }

  p {
    font: 700 16px/1.4 $roboto;
    color: $black;
    text-transform: uppercase;
    letter-spacing: 1.65px;
    max-width: 18em;

    @include md {
      font-size: 20px;
    }
  }

  .btn-video {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;

    @include sm {
      right: 10%;
    }

    @include md {
      right: inherit;
      left: 22.25em;
      top: 4em;
    }
  }
}

.benefits {
  position: relative;
  padding-top: 97px;

  display: flex;
  flex-direction: column-reverse;

  @include md {
    display: block;
  }

  @include xl {
    padding-bottom: 223px;
    display: block;
  }

  h2 {
    letter-spacing: 3px;
  }

  &__inner {
    @include md {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
    }
  }

  &-info {

    @include md {
      max-width: 50%;
      padding-left: 15px;
    }

    @include xl {
      max-width: 39em;
      padding-left: 0;
    }

    &__inner {

      @include xl {
        display: flex;
      }
    }

    &__cont {
      margin-left: 8em;
    }

    &__item {
      &--top {
        max-width: 15em;
        margin-right: 1em;
        margin-top: 49px;
      }

      &--bottom {
        margin-top: 2em;

        @include xl {
          margin-top: 17.3em;

        }
      }
    }
  }

  &__v-txt {
    position: absolute;
    font: 172px/1 $rubik;
    color: #f8f8f8;
    top: 116px;
    right: -45px;
    z-index: -1;
    text-align: right;
    transform: rotate(-90deg);


    span {
      letter-spacing: -35px;
    }
  }

  &-video {
    background: url("../img/video-bg.jpg") no-repeat center / cover;
    width: 100%;
    height: 300px;
    position: relative;

    @include md {
      width: 50vw;
      height: 750px;
      position: absolute;
      top: 0;
      left: 0;

      @include xl {
        width: 38.4vw;
      }
    }

    &__btn {
      position: absolute;
      width: 100px;
      height: 100px;
      background: rgba(255, 255, 255, .8) url('../img/icon-play.svg') no-repeat 60% / 40px 50px;
      top: 0;
      bottom: 0;
      left: -24px;
      right: 0;
      margin: auto;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .models {
    margin-top: 59px;
    max-width: 23em;
  }

  .models-color {
    display: flex;
    justify-content: flex-end;

    &__list {
      display: flex;
      width: 13em;
      justify-content: space-between;
      padding: 0;
      margin: 0;
      margin-right: 1em;
    }

    &__item {
      list-style-type: none;

      &.active {
        span {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            width: 1px;
            height: 50px;
            background: #cdcdcd;
            left: 0;
            right: 0;
            margin: auto;
            top: calc(100% + 10px);
          }

          &:after {
            content: '';
            position: absolute;
            width: 3px;
            height: 3px;
            background: #cdcdcd;
            left: 0;
            right: 0;
            margin: auto;
            top: calc(100% + 60px);
          }
        }
      }
    }

    &__link {
      width: 20px;
      height: 20px;
      border-radius: 50px;
      display: block;
      cursor: pointer;
    }

    &--black {
      background: #0c0c0c;
    }

    &--gray {
      background: #f0efef;
    }

    &--yellow {
      background: #e2e837;
    }

    &--red {
      background: #e23232;
    }

    &--blue {
      background: #00b6f1;
    }
  }

  .models-photo {
    &__list {
      margin-top: 32px;
    }

    &__item {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

.modes {
  position: relative;
  padding-bottom: 50px;
  margin-bottom: 50px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    background: url('../img/section-img-bg.png') no-repeat center / 100% 100%;
    top: -30px;
    left: 0;
    width: 300px;
    height: 515px;
    z-index: -1;

    @include lg {
      width: 511px;
      height: 878px;
    }
  }

  .btn-video {
    left: 23em;
    z-index: 11;
    top: 90px;
  }

  @include xl {
    padding-bottom: 248px;
  }

  &__img {
    background: url("../img/modes-bg.jpg") no-repeat right / cover;
    height: 300px;

    @include md {
      position: absolute;
      width: 50%;
      top: 0;
      right: 0;
      height: 100%;
    }

    @include lg {
      width: 55.3vw;
    }
  }

  &__inner {
    padding-top: 48px;
  }

  .infobox {
    max-width: 21em;
  }

  .properties {
    margin-top: 75px;
    max-width: 20em;

    &__list {
      display: flex;
    }

    &__item {
      margin-right: 60px;
    }

    &__title {
      font: 60px/1 $rubik;
      color: $blue;
      display: flex;
      align-items: flex-end;
      margin-bottom: 15px;

      span {
        font-size: 24px;
        margin-bottom: 8px;
        min-width: 90px;
      }
    }

    &__desc {
      font: 16px/1.25 $roboto;
      color: $gray;
    }
  }
}

.appointment {
  position: relative;
  padding-top: 100px;
  margin-top: 181px;
  background: url('../img/appointment-bg.jpg') no-repeat left / cover;
  color: #fff;

  @include md {
    padding-bottom: 100px;
  }

  @include lg {
    padding-bottom: 222px;
  }

  &__inner {
    max-width: 20em;
  }

  &__img {
    background: url("../img/appointment-img.jpg") no-repeat right / cover;
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;

    @include md {
      width: 50%;
      height: 100%;
      position: absolute;
      right: 0;
      top: -60px;
    }

    @include lg {
      width: 61.4%;
      bottom: 100px;
      height: auto;
    }
  }

  &__v-txt {
    font: 185px/1 $rubik;
    color: rgba(255, 255, 255, 0.5);
    left: -40px;
    bottom: -50px;
    position: absolute;
    text-align: right;
  }

  .infobox {
    margin-top: 80px;
    line-height: 30px;
    padding-right: 28px;

    p {
      line-height: 30px;
    }
  }

  .btn {
    margin-top: 105px;
  }
}

.footer {
  background: #1b1b1b;

  .copy {
    text-align: center;
    font-size: 12px;
    padding: 5px 0;
    color: $gray;
  }
}

.btn-video {
  position: relative;
  padding-left: 30px;
  display: block;
  height: 75px;

  svg {
    width: 80px;
  }

  &__text {
    transform: rotate(-90deg);
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: 0;
    text-transform: uppercase;
    color: $black;
    font: 700 12px/1 $roboto;
    max-width: 6em;
    letter-spacing: 1px;
    transform-origin: 12px 10px;
  }
}

.burger {
  position: absolute;
  top: 28px;
  right: 18px;
  width: 24px;
  height: 17px;
  cursor: pointer;
  z-index: 12;

  @include md {
    display: none;
  }
}

.burger span,
.burger span:before,
.burger span:after {
  width: 100%;
  position: absolute;
  height: 3px;
  background: $blue;
  display: block;
  transition: .3s;
}

.burger span:before {
  content: "";
  top: -6px;
  transition: top 150ms 150ms, transform 150ms cubic-bezier(0.23, 1, 0.32, 1);
}

.burger span {
  top: 6px;
}

.burger span:after {
  content: "";
  bottom: -6px;
  transition: bottom 150ms 150ms, transform 150ms cubic-bezier(0.23, 1, 0.32, 1);
}

.burger span.active:before {
  content: "";
  top: 0;
  transform: rotate(45deg);
  transition: top 150ms, transform 150ms 150ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.burger span.active:after {
  content: "";
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 150ms, transform 150ms 150ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.burger span.active {
  background: rgba(255, 255, 255, 0);
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body {
  .blocker {
    z-index: 15;
  }

  .modal {
    z-index: 99;
  }
}