.section {

    &__title {
        font: 700 18px/1 $roboto;
        color: $blue;
        text-transform: uppercase;
        letter-spacing: 3px;


        &--white {
            color: #fff;
        }
    }

    &--blue-bg {
        background-color: $blue;
        color: #fff;
    }
}


.marker-list {
    list-style: none;
    position: relative;
    padding-left: 0;
    margin-top: 25px;

    &__item {
        padding-left: 45px;
        position: relative;
        margin-bottom: 24px;
        line-height: 1.5;

        &:before {
            content: '';
            position: absolute;
            width: 30px;
            height: 5px;
            left: 0;
            top: 10px;
            bottom: 0;
            background-color: $black;
        }
    }
}