html {
  font: 400 18px/1.25 $roboto;
  color: $black;
  background: #fff;

  &.active {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

nav {
  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;

      a {
        text-decoration: none;
      }
    }
  }
}

b,
strong {
  font-weight: bold;
}

p{
  line-height: 1.5;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}


h1 {
  font: 30px/1.2 $rubik;
  letter-spacing: 6px;

  @include sm {
    font-size: 40px;
  }

  @include md {
    font-size: 60px;
  }

  @include lg {
    font-size: 80px;
  }
}

h2 {
  font: 900 20px/1.25 $rubik;
  margin-bottom: .6em;
  letter-spacing: 2px;


  @include md {
    font-size: 40px;
  }
}